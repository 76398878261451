import { createRouter, createWebHistory } from 'vue-router'
import store from '../store/index'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/Home.vue'),
    meta: {
      requireLogin: true,
      active: 'home'
    }
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('../views/SettingsView.vue'),
    meta: {
      requireLogin: true,
      active: 'settings',
      transition: 'slide-in'
    }
  },
  {
    path: '/admin/create-team',
    name: 'admin_create_team',
    component: () => import('../views/teams/CreateTeamView.vue'),
    meta: {
      requireLogin: true,
      requireAdmin: true,
      active: 'settings'
    }
  },
  {
    path: '/admin/create-user',
    name: 'admin_create_user',
    component: () => import('../views/users/CreateUserView.vue'),
    meta: {
      requireLogin: true,
      requireAdmin: true,
      active: 'settings'
    }
  },
  {
    path: '/admin/update-user/:id',
    name: 'admin_update_user',
    component: () => import('../views/users/UpdateUserView.vue'),
    meta: {
      requireLogin: true,
      requireAdmin: true,
      active: 'settings'
    }
  },
  {
    path: '/admin/create-event',
    name: 'admin_create_event',
    component: () => import('../views/events/CreateEventAdminView.vue'),
    meta: {
      requireLogin: true,
      requireAdmin: true,
      active: 'settings'
    }
  },
  {
    path: '/admin/create-season',
    name: 'admin_create_season',
    component: () => import('../views/seasons/CreateSeasonView.vue'),
    meta: {
      requireLogin: true,
      requireAdmin: true,
      active: 'settings'
    }
  },
  {
    path: '/admin/teams',
    name: 'admin_teams',
    component: () => import('../views/teams/TeamsView.vue'),
    meta: {
      requireLogin: true,
      requireAdmin: true,
      active: 'settings'
    }
  },
  {
    path: '/admin/teams/:id',
    name: 'admin_team',
    component: () => import('../components/Admin/Team.vue'),
    meta: {
      requireLogin: true,
      requireAdmin: true,
      active: 'settings'
    }
  },
  {
    path: '/teams/:id',
    name: 'team',
    component: () => import('../views/teams/TeamView.vue'),
    meta: {
      requireLogin: true,
      active: 'settings'
    }
  },
  {
    path: '/admin/players',
    name: 'admin_players',
    component: () => import('../views/users/UsersView.vue'),
    meta: {
      requireLogin: true,
      requireAdmin: true,
      active: 'settings'
    }
  },
  {
    path: '/admin/seasons',
    name: 'admin_seasons',
    component: () => import('../views/seasons/SeasonsView.vue'),
    meta: {
      requireLogin: true,
      requireAdmin: true,
      active: 'settings'
    }
  },
  {
    path: '/admin/events',
    name: 'admin_events',
    component: () => import('../views/events/AdminEventsView.vue'),
    meta: {
      requireLogin: true,
      requireAdmin: true,
      active: 'settings'
    }
  },
  {
    path: '/create-event',
    name: 'create_event',
    component: () => import('../views/events/CreateEventView.vue'),
    meta: {
      requireLogin: true,
      // requireAdmin: true,
      active: 'settings'
    }
  },
  {
    path: '/create-activity',
    name: 'create_activity',
    component: () => import('../views/events/CreateActivityView.vue'),
    meta: {
      requireLogin: true,
      // requireAdmin: true,
      active: 'settings'
    }
  },
  {
    path: '/create-training',
    name: 'create_training',
    component: () => import('../views/events/CreateTrainingView.vue'),
    meta: {
      requireLogin: true,
      // requireAdmin: true,
      active: 'settings'
    }
  },
  {
    path: '/create-game',
    name: 'create_game',
    component: () => import('../views/events/CreateGameView.vue'),
    meta: {
      requireLogin: true,
      // requireAdmin: true,
      active: 'settings'
    }
  },
  {
    path: '/me',
    name: 'my-profile',
    component: () => import('../views/users/ProfileView.vue'),
    meta: {
      requireLogin: true,
      active: 'settings'
    }
  },
  {
    path: '/my-teams',
    name: 'my-teams',
    component: () => import('../views/teams/MyTeamsView'),
    meta: {
      requireLogin: true,
      active: 'settings'
    }
  },
  {
    path: '/events',
    name: 'events',
    component: () => import('../views/events/EventsView.vue'),
    meta: {
      requireLogin: true,
      active: 'events'
    }
  },
  {
    path: '/last-events',
    name: 'last_events',
    component: () => import('../views/votes/LastGamesView.vue'),
    meta: {
      requireLogin: true,
      active: 'votes'
    }
  },
  {
    path: '/events/:id',
    name: 'event',
    component: () => import('../views/events/EventView.vue'),
    meta: {
      requireLogin: true,
      active: 'events'
    }
  },
  {
    path: '/convoc/:id',
    name: 'convoc',
    component: () => import('../views/events/ConvocationView.vue'),
    meta: {
      requireLogin: true,
      active: 'events'
    }
  },
  {
    path: '/last-events/:id',
    name: 'last_event',
    component: () => import('../views/events/PastEventView.vue'),
    meta: {
      requireLogin: true,
      active: 'votes'
    }
  },
  {
    path: '/last-games/:id',
    name: 'last_game',
    component: () => import('../views/events/PastGameComponent.vue'),
    meta: {
      requireLogin: true,
      active: 'votes'
    }
  },
  {
    path: '/team/:id/rules',
    name: 'team_rules',
    component: () => import('../views/teams/RuleView.vue'),
    meta: {
      requireLogin: true,
      active: 'settings'
    }
  },
  {
    path: '/update-team/:id',
    name: 'update_team',
    component: () => import('../views/teams/UpdateTeamView.vue'),
    meta: {
      requireLogin: true,
      active: 'settings'
    }
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import('../views/Admin.vue'),
    children: [
      { path: 'members', name: 'admin_members', component: () => import('../components/Admin/Members.vue') },
      { path: 'members/:user_id', name: 'admin_member', component: () => import('../components/Admin/Member.vue') },
      { path: 'members/:user_id/edit', name: 'admin_member_edit', component: () => import('../components/Admin/EditMember.vue') },
      { path: 'stats', name: 'admin_stats', component: () => import('../components/Admin/Stats.vue') },
      { path: 'subscriptions', name: 'admin_licences', component: () => import('../components/Admin/Subscriptions.vue') },
    ],
    meta: {
      requireLogin: true,
      requireAdmin: true,
    }
  },
  {
    path: '/addteam/',
    name: 'addteam',
    component: () => import('../views/teams/AddTeam.vue'),
    meta: {
      requireLogin: true,
      active: 'settings'
    }
  },
  {
    path: '/addteamuser/:id',
    name: 'add_team_user',
    component: () => import('../views/teams/AddUser.vue'),
    meta: {
      requireLogin: true,
      active: 'settings'
    }
  },
  {
    path: '/newuser/',
    name: 'newuser',
    component: () => import('../views/NoTeam.vue'),
    meta: {
      requireLogin: true,
      active: 'settings'
    }
  },
  {
    path: '/readqr/',
    name: 'readqr',
    component: () => import('../views/Scan.vue'),
    meta: {
      requireLogin: true,
      active: 'settings'
    }
  },
  {
    path: '/addteam/:uuid',
    name: 'addteamuuid',
    component: () => import('../views/AddTeamView.vue'),
    meta: {
      requireLogin: true,
      active: 'settings'
    }
  },
  {
    path: '/change-password/',
    name: 'change-pwd',
    component: () => import('../views/me/ChangePwdView.vue'),
    meta: {
      active: 'settings'
    }
  },
  {
    path: '/reset-password/',
    name: 'reset-pwd',
    component: () => import('../views/ForgotPwd.vue'),
  },
  {
    path: '/password-reset/:uid/:token',
    name: 'pwd-reset',
    component: () => import('../views/ResetPwd.vue'),
  },
  {
    path: '/events/:id/poll',
    name: 'event_poll',
    component: () => import('../views/votes/EventPollView.vue'),
    meta: {
      requireLogin: true,
      active: 'votes'
    }
  },
  {
    path: '/event/:id/vote',
    name: 'vote',
    component: () => import('../views/votes/Vote.vue'),
    meta: {
      requireLogin: true,
      active: 'votes'
    }
  },
  {
    path: '/event/:id/votes',
    name: 'votes',
    component: () => import('../views/votes/Votes.vue'),
    meta: {
      requireLogin: true,
      active: 'votes'
    }
  },
  {
    path: '/read-vote/:id',
    name: 'read',
    component: () => import('../views/votes/ReadVote.vue'),
    meta: {
      requireLogin: true,
      active: 'votes'
    }
  },
  {
    path: '/status/:id',
    name: 'status',
    component: () => import('../views/votes/Status.vue'),
    meta: {
      requireLogin: true,
      active: 'votes'
    }
  },
  {
    path: '/results/:id',
    name: 'results',
    component: () => import('../views/votes/Results.vue'),
    meta: {
      requireLogin: true,
      active: 'votes'
    }
  },
  {
    path: '/results/:id/edit',
    name: 'edit-result',
    component: () => import('../views/votes/EditResult.vue'),
    meta: {
      requireLogin: true,
      active: 'votes'
    }
  },
  {
    path: '/old_team/:id',
    name: 'old_team',
    component: () => import('../views/TeamAdmin.vue'),
    children: [
      // Main
      { path: 'stats', name: 'stats', component: () => import('../components/Teams/Stats.vue') },
      // Settings
      { path: 'my-stats', name: 'my-stats', component: () => import('../components/Home/MyStats.vue') },
      { path: 'qr', name: 'qr', component: () => import('../components/Teams/QRShow.vue') },
      { path: 'add-team', name: 'add-team', component: () => import('../views/teams/AddTeam.vue') },
      { path: 'members', name: 'members', component: () => import('../components/Teams/Members.vue') },
      { path: 'member/:user_id', name: 'member', component: () => import('../components/Teams/Member.vue') },
      { path: 'rules', name: 'rules', component: () => import('../components/Teams/Rules.vue') },
      { path: 'info', name: 'info', component: () => import('../components/Teams/Info.vue') },
      // games
      { path: 'games', name: 'games', component: () => import('../components/Teams/Games.vue') },
      { path: 'next-games', name: 'next-games', component: () => import('../components/Teams/NextGames.vue') },
      // { path: 'game/:game_id', name: 'game', component: () => import('../components/Teams/Game.vue') },
      { path: 'game-coach/:game_id', name: 'coach', component: () => import('../components/Teams/GameCoachView.vue') },
      // Vote result
      // { path: 'results/:game_id/edit', name: 'edit-result', component: () => import('../views/votes/EditResult.vue') },
      // { path: 'results/:game_id', name: 'results', component: () => import('../components/Teams/Results.vue') },
      { path: 'vote/:game_id/results', name: 'vote-results', component: () => import('../views/votes/VoteResult.vue') },
      // Used ?
      { path: 'teams', name: 'teams', component: () => import('../components/Teams/Teams.vue') },

    ],
    meta: {
      requireLogin: true,
    }
  },
  {
    path: '/expired',
    name: 'expired',
    component: () => import('../views/ExpireView.vue'),
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/error',
    name: 'error',
    component: () => import('../views/ErrorView.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/signup',
    name: 'signup',
    component: () => import('../views/Signup.vue')
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import('../views/logout.vue')
  },
  {
    path: '/insta',
    name: 'insta',
    component: () => import('../views/InstaView.vue'),
    children: [
      { path: 'votes/:game_id', name: 'votes_result', component: () => import('../components/Insta/VoteResult.vue') },
    ],
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  mode: 'out-in', // Use 'out-in' mode for smooth transitions
})

// GOOD

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requireLogin) && !store.state.isAuthenticated) {
    next({ name: 'login', query: { to: to.path } });
  } else if (to.matched.some(record => record.meta.requireAdmin) && !store.state.user.is_staff) {
    next({ name: 'home' })
  } else {
    store.commit('setActive', to.meta.active)
    next()
  }
})

export default router
