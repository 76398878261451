import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './index.css'
import axios from "axios";
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URL;
axios.interceptors.response.use(
    function (response) {
        return response
    },
    async function (error) {
        if (error.response.status === 401) {
            await store.commit('logout')
            await store.commit('removeToken')
            await router.push('/login')
        } else if (error.response.status === 409) {
            await store.commit('setTeam', {})
        }
        return Promise.reject(error)
    })
createApp(App).use(store).use(router).component('Datepicker', Datepicker).mount('#app')
