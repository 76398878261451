<template>
  <div class="grid grid-cols-5 place-items-center w-full text-nav bg-white text-gray-400">
    <router-link :to="{name: 'home'}"
                 :class="active === 'home' ? 'bg-sky-blue text-white' :  ''"
                 class="flex flex-col items-center justify-around px-4 aspect-square w-full rounded-t">
      <HomeIcon class="icon"></HomeIcon>
    </router-link>
    <router-link :to="{name: 'events'}"
                 :class="active === 'events' ? 'bg-sky-blue text-white' :  ''"
                 class="flex flex-col items-center justify-around px-4 aspect-square w-full rounded-t">
      <CalendarIcon class="icon"></CalendarIcon>
    </router-link>
    <router-link :to="{name: 'last_events'}"
                 :class="active === 'votes' ? 'bg-sky-blue text-white' :  ''"
                 class="flex flex-col items-center justify-around px-4 aspect-square w-full rounded-t">
      <FolderOpenIcon class="icon"></FolderOpenIcon>
    </router-link>
    <router-link :to="{name: 'last_events'}"
                 :class="active === 'notifications' ? 'bg-sky-blue text-white' :  ''"
                 class="flex flex-col items-center justify-around px-4 aspect-square w-full rounded-t">
      <BellIcon class="icon"></BellIcon>
    </router-link>
    <router-link :to="{name: 'settings'}"
                 :class="active === 'settings' ? 'bg-sky-blue text-white' :  ''"
                 class="flex flex-col items-center justify-around px-4 aspect-square w-full rounded-t">
      <Cog6ToothIcon class="icon"></Cog6ToothIcon>
    </router-link>
  </div>
</template>

<script>

import {HomeIcon, CalendarIcon, Cog6ToothIcon, BellIcon, FolderOpenIcon} from '@heroicons/vue/24/outline';

export default {
  name: "AppMenu",
  components: {
    HomeIcon,
    CalendarIcon,
    Cog6ToothIcon,
    BellIcon,
    FolderOpenIcon
  },
  computed: {
    active () {
      return this.$store.state.active
    }
  }
}
</script>

<style scoped>
.icon {
  height: 32px; margin-bottom: 2px
}
.middle-icon {
  height: 56px;
  width: 56px;
}

</style>
